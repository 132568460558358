import { gql } from 'graphql-tag';

import { medium1x1ImageFragment } from './image.fragments';

/**
 * Important:
 * If you change params of these queries, make sure to update cache resolving in ~/plugins/apollo.ts
 */

export const activePerformancesQuery = gql`
 query activePerformances ($limit: Int, $performanceTags: [ID!]) {
   scenkonstOtPerformances (
     archived: false,
     limit: $limit,
     performanceTags: $performanceTags
   ) {
     id
     title
     subtitle
     pageUrl
     startDate
     endDate
     status
     image {
       ...Medium1x1ImageData
     }
   }
 }
 ${medium1x1ImageFragment}
`;

export const archivedPerformancesQuery = gql`
  query archivedPerformances ($limit: Int, $offset: Int, $seasons: [ID!]) {
    scenkonstOtPerformances (
      limit: $limit,
      offset: $offset,
      seasons: $seasons,
      archived: true
    ) {
      id
      title
      subtitle
      pageUrl
      seasons {
        season
      }
      image {
        ...Medium1x1ImageData
      }
    }
  }

  ${medium1x1ImageFragment}
`;
